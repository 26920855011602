import { createContext, ReactNode, useReducer } from 'react';
// @types
import { ActionMap } from '../@types/auth';
import { Lesson, LessonContextState, LessonContextType } from 'src/@types/lesson';
import LessonService from 'src/services/LessonService';

// ----------------------------------------------------------------------

enum Types {
  Register = 'REGISTER',
  ChangeDay = "CHANGE_DAY",
  ChangeType = "CHANGE_TYPE"
}

type LessonPayload = {
  [Types.Register]: {
    lesson: Lesson;
  };
  [Types.ChangeDay]: {
    day: number;
  };
  [Types.ChangeType]: {
    type: string;
  };
};

export type LessonActions = ActionMap<LessonPayload>[keyof ActionMap<LessonPayload>];

const initialState: LessonContextState = {
  lesson: null,
  day: 1,
  type: "list"
};

const LessonReducer = (state: LessonContextState, action: LessonActions) => {
  switch (action.type) {
    case 'REGISTER':
      return {
        ...state,
        lesson: action.payload.lesson,
      };
    case 'CHANGE_DAY':
      return {
        ...state,
        day: action.payload.day,
      };
    case 'CHANGE_TYPE':
      return {
        ...state,
        type: action.payload.type,
      };
    default:
      return state;
  }
};

const LessonContext = createContext<LessonContextType | null>(null);

// ----------------------------------------------------------------------

type LessonProviderProps = {
  children: ReactNode;
};

function LessonProvider({ children }: LessonProviderProps) {
    const [state, dispatch] = useReducer(LessonReducer, initialState);

    const register = async (id :number | string) => {
        const response = await LessonService.getOverview(id);
        dispatch({
            type: Types.Register,
            payload: {
                lesson: response.data
            }
        });
    }

    const changeDay = (day: number) => {
      dispatch({
        type: Types.ChangeDay,
        payload: {
          day
        }
      })
    }

    const changeType = (type: string) => {
      dispatch({
        type: Types.ChangeType,
        payload: {
          type
        }
      })
    }

    return (
        <LessonContext.Provider
            value={{
                ...state,
                register,
                changeDay,
                changeType
            }}
        >
        {children}
        </LessonContext.Provider>
    );
}

export { LessonContext, LessonProvider };
