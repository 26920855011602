// ----------------------------------------------------------------------

export function randomNumber(number: number) {
  	return Math.floor(Math.random() * number) + 1;
}

export function randomNumberRange(min: number, max: number) {
  	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function randomInArray(array: any) {
  	return array[Math.floor(Math.random() * array.length)];
}

export function convertObjectToFormData(o: object): FormData {
	return Object.entries(o).reduce((d,e) => (d.append(...e),d), new FormData());
}

interface IDictionary<TValue> {
    [id: string]: TValue;
}

export function filterNull(filter: object, strict=false) {
	let _filter: IDictionary<string> = { ...filter };
	Object.keys(filter).forEach((item: string) => {
		if (_filter[item] === null || _filter[item] === undefined || (strict && _filter[item].length === 0))
			delete _filter[item]
	})
	return _filter;
}

export function intersectObject(o: IDictionary<string>, _o: IDictionary<string>) {
	const keyArr = Object.keys(o);
	let result: IDictionary<string> = {};
	keyArr.forEach(item => {
		if (o[item] !== _o[item])
			result[item] = o[item];
	})
	return result;
}