import { HOST_V2_API } from 'src/config';
import fetch from './interceptors';

const PackageService = {
  getPackages: function (params?: object) {
    return fetch({
      url: '/api/corp/ajaxpackage',
      method: 'get',
      params,
    });
  },
  getPackageRequests: function (params?: object) {
    return fetch({
      url: '/api/corp/bkrequest/data',
      method: 'get',
      params,
    });
  },
  addPackageRequest: function (data: any) {
    return fetch({
      url: '/api/corp/create/bkrequest',
      method: 'post',
      data,
    });
  },
  getPackageOverview: function (id: number | string, params?: object) {
    return fetch({
      url: `/api/corp/package/${id}/overview`,
      method: 'get',
      params,
    });
  },
  getPackageWords: function (id: number | string, params?: object) {
    return fetch({
      url: `/api/corp/ajaxword/${id}`,
      method: 'get',
      params,
    });
  },
  getPosType: function (languageId: number | string, params?: object) {
    return fetch({
      url: `/api/corp/partsofspeech/${languageId}`,
      method: 'get',
      params,
    });
  },
  getWordDetail: function (packageId: number | string, wordId: number | string, params?: object) {
    return fetch({
      baseURL: HOST_V2_API,
      url: `/api/v2/package/action/${packageId}/word/${wordId}`,
      method: 'get',
      params,
    });
  },
  createWord: function (id: number | string, data: any) {
    return fetch({
      baseURL: HOST_V2_API,
      url: `/api/v2/package/action/${id}/word/`,
      method: 'post',
      data,
    });
  },
  updateWord: function (packageId: number | string, id: number | string, data: any) {
    return fetch({
      baseURL: HOST_V2_API,
      url: `/api/v2/package/action/${packageId}/word/${id}`,
      method: 'post',
      data,
    });
  },
  deleteWord: function (packageId: number | string, id: number | string, params?: object) {
    return fetch({
      baseURL: HOST_V2_API,
      url: `/api/v2/package/action/${packageId}/word/${id}`,
      method: 'delete',
      params,
    });
  },

  migrateWords: function (packageId: number | string, data?: object) {
    return fetch({
      baseURL: HOST_V2_API,
      url: `/api/v2/package/action/${packageId}/migrate`,
      method: 'post',
      data,
    });
  },
};

export default PackageService;
