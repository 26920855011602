import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
// utils
import axios from '../../utils/axios';
// @types
import { KanbanCard, KanbanColumn } from '../../@types/kanban';
//
import { dispatch } from '../store';
import { WordOrderColumn } from 'src/@types/lesson';
import { Word } from 'src/@types/package';
import LessonService from 'src/services/LessonService';

// ----------------------------------------------------------------------

function objFromArray<Type extends Record<string, any>>(array: Type[], key: string = 'id') {
  return array.reduce<Record<string, Type>>((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;
  board: {
    words: Record<string, Word>;
    columns: Record<string, WordOrderColumn>;
    columnOrder: number[];
  };
};

const initialState: InitialState = {
  isLoading: false,
  error: null,
  board: {
    words: {},
    columns: {},
    columnOrder: []
  },
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    getBoardSuccess(state, action) {
      state.isLoading = false;
      const board = action.payload;
      const words = objFromArray<Word>(board.words);
      const columns = objFromArray<WordOrderColumn>(board.columns);
      const { columnOrder } = board;
      state.board = {
        words,
        columns,
        columnOrder
      };
    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      // const newColumn = action.payload;
      // state.isLoading = false;
      // state.board.columns = {
      //   ...state.board.columns,
      //   [newColumn.id]: newColumn,
      // };
      // state.board.columnOrder.push(newColumn.id);
    },

    persistCard(state, action) {
      const columns = action.payload;
      state.board.columns = columns;
    },

    persistColumn(state, action) {
      // state.board.columnOrder = action.payload;
    },

    addTask(state, action) {
    //   const { card, columnId } = action.payload;

    //   state.board.cards[card.id] = card;
    //   state.board.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      // const { cardId, columnId } = action.payload;

      // state.board.columns[columnId].cardIds = state.board.columns[columnId].cardIds.filter(
      //   (id) => id !== cardId
      // );

      // state.board.cards = omit(state.board.cards, [cardId]);
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {
      // const column = action.payload;

      // state.isLoading = false;
      // state.board.columns[column.id] = column;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      const { columnId } = action.payload;
      const deletedColumn = state.board.columns[columnId];

      state.isLoading = false;
      state.board.columns = omit(state.board.columns, [columnId]);
      state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId);
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getBoard(id: number | string, type: "exam" | "list") {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await LessonService.getLessonOrderDays(id, { type });
      dispatch(slice.actions.getBoardSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createColumn(newColumn: { name: string }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/kanban/columns/new', newColumn);
      dispatch(slice.actions.createColumnSuccess(response.data.column));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateColumn(columnId: string, updateColumn: KanbanColumn) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/kanban/columns/update', {
        columnId,
        updateColumn,
      });
      dispatch(slice.actions.updateColumnSuccess(response.data.column));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteColumn(columnId: number | string, lessonId: number | string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await LessonService.deleteFinalExam(lessonId);
      dispatch(slice.actions.deleteColumnSuccess({ columnId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder: string[]) {
  return () => {
    dispatch(slice.actions.persistColumn(newColumnOrder));
  };
}

// ----------------------------------------------------------------------

export function persistCard(id: string | number, data: any, column: Record<string, WordOrderColumn>) {
  return () => {
    LessonService.changeLessonWordOrder(id, data);
    dispatch(slice.actions.persistCard(column));
  };
}

// ----------------------------------------------------------------------

export function addTask({ card, columnId }: { card: Partial<KanbanCard>; columnId: string }) {
  return () => {
    dispatch(slice.actions.addTask({ card, columnId }));
  };
}

// ----------------------------------------------------------------------

export function deleteTask({ cardId, columnId }: { cardId: string; columnId: number }) {
  return () => {
    dispatch(slice.actions.deleteTask({ cardId, columnId }));
  };
}
