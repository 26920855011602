const dev = {
  //     API_ENDPOINT_URL: 'https://hippo.cards'
  API_ENDPOINT_URL: 'https://hippo.cards',
};
  
  const prod = {
      API_ENDPOINT_URL: 'https://hippo.cards'
  };
  
  const test = {
    API_ENDPOINT_URL: 'https://api.test.com'
  };
  
  const getEnv = () => {
      switch (process.env.NODE_ENV) {
          case 'development':
                return dev
          case 'production':
                return prod
          case 'test':
                return test
          default:
                return dev
              break;
      }
  }
  
  export const env = getEnv()
  