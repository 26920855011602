// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Checkbox, FormControlLabel, FormGroup, FormControlLabelProps, Grid } from '@mui/material';

// ----------------------------------------------------------------------

interface RHFCheckboxProps extends Omit<FormControlLabelProps, 'control'> {
  name: string;
}

export function RHFCheckbox({ name, ...other }: RHFCheckboxProps) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => <Checkbox {...field} checked={field.value} />}
        />
      }
      {...other}
    />
  );
}

// ----------------------------------------------------------------------

interface RHFMultiCheckboxProps extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string;
  options: string[];
}

export function RHFMultiCheckbox({ name, options, ...other }: RHFMultiCheckboxProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option: string) =>
          field.value.includes(option)
            ? field.value.filter((value: string) => value !== option)
            : [...field.value, option];

        return (
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    checked={field.value.includes(option)}
                    onChange={() => field.onChange(onSelected(option))}
                  />
                }
                label={option}
                {...other}
              />
            ))}
          </FormGroup>
        );
      }}
    />
  );
}

interface RHFMultiCheckboxPropsOverride extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string;
  options: {
    id: number,
    name: string
  }[];
}

export function RHFMultiCheckboxOverride({ name, options, ...other }: RHFMultiCheckboxPropsOverride) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option: number) =>
          field.value.includes(option)
            ? field.value.filter((value: number) => value !== option)
            : [...field.value, option];

        return (
          <FormGroup sx={{ width: "100%" }}>
            <Grid container>
              {options.map((option) => (
                <Grid item sm={ 4 } key={option.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value.includes(option.id)}
                        onChange={() => field.onChange(onSelected(option.id))}
                      />
                    }
                    label={option.name}
                    {...other}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        );
      }}
    />
  );
}