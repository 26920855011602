import fetch from './interceptors'

const LessonService = {
    getLessons: function (params=null) {
        return fetch({
            url: '/api/corp/lesson',
            method: 'get',
            params
        });
    },
    getLessonOrderDays: function (id: string | number, params?: any) {
        return fetch({
            url: `/api/corp/lesson/${id}`,
            method: 'get',
            params
        });
    },
    changeLessonWordOrder: function (id: string | number, data: any) {
        return fetch({
            url: `/api/corp/lesson/${id}/move`,
            method: 'post',
            data
        })
    },
    getLessonDayWords: function (id: string | number, params?: any) {
        return fetch({
            url: `/api/corp/lesson/${id}/group`,
            method: 'get',
            params
        });
    },
    getOverview: function (id: string | number, params?: any) {
        return fetch({
            url: `/api/corp/lesson/${id}/overview`,
            method: 'get',
            params
        });
    },
    createFinalExam: function (id: string | number, data?: any) {
        return fetch({
            url: `/api/corp/lesson/${id}/final`,
            method: 'post',
            data
        })
    },
    deleteFinalExam: function (id: string | number, data?: any) {
        return fetch({
            url: `/api/corp/lesson/${id}/final`,
            method: 'delete',
            data
        })
    },
    createLesson: function (data?: any) {
        return fetch({
            url: `/api/corp/lesson/`,
            method: 'post',
            data
        })
    },
    getLastLesson: function (params?: any) {
        return fetch({
            url: `/api/corp/lesson/group/last`,
            method: 'get',
            params
        });
    },
    enrollLesson: function (data?: any) {
        return fetch({
            url: `/api/corp/lesson/user`,
            method: 'post',
            data
        })
    },
    getLessonUsers: function (id: number | string, params?: any) {
        return fetch({
            url: `/api/corp/lesson/${id}/user`,
            method: 'get',
            params
        });
    },
    removeLessonUser: function (id: number | string, data?: any) {
        return fetch({
            url: `/api/corp/lesson/user/${id}`,
            method: 'delete',
            data
        })
    },
    updateScore: function (id: number | string, data?: any) {
        return fetch({
            url: `/api/corp/lesson/user/${id}/score`,
            method: 'post',
            data
        })
    },
}

export default LessonService