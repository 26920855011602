import { createContext, ReactNode, useReducer } from 'react';
// @types
import { ActionMap } from '../@types/auth';
import { Package, PackageContextState, PackageContextType } from 'src/@types/package';
import PackageService from 'src/services/PackageService';

// ----------------------------------------------------------------------

enum Types {
  Register = 'REGISTER',
}

type LessonPayload = {
  [Types.Register]: {
    package: Package;
  };
};

export type LessonActions = ActionMap<LessonPayload>[keyof ActionMap<LessonPayload>];

const initialState: PackageContextState = {
  package: null,
};

const LessonReducer = (state: PackageContextState, action: LessonActions) => {
  switch (action.type) {
    case 'REGISTER':
      return {
        ...state,
        package: action.payload.package,
      };
    default:
      return state;
  }
};

const PackageContext = createContext<PackageContextType | null>(null);

// ----------------------------------------------------------------------

type PackageProviderProps = {
  children: ReactNode;
};

function PackageProvider({ children }: PackageProviderProps) {
    const [state, dispatch] = useReducer(LessonReducer, initialState);

    const register = async (id :number | string) => {
        const response = await PackageService.getPackageOverview(id);
        dispatch({
            type: Types.Register,
            payload: {
                package: response.data
            }
        });
    }

    return (
        <PackageContext.Provider
            value={{
                ...state,
                register,
            }}
        >
        { children }
        </PackageContext.Provider>
    );
}

export { PackageContext, PackageProvider };
